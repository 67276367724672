@page {
  size: A5;
  margin: 0;
}

@media print {
  body, html {
      width: 14.85cm !important;
      height: 21cm !important;
      margin: 0;
  }
  .print-preview {
      width: 14.85cm;
      height: 21cm;
      margin: 0;
      /* border: 5px solid #000; */
  }
}